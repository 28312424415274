import React from "react"

const Wall = () => (
    <svg width="422" height="349" viewBox="0 0 422 349" fill="none" xmlns="http://www.w3.org/2000/svg" className="img-resp">
        <desc>Illustration of a man on wall pulling someone elses hand to help them get over the wall</desc>
        <path d="M85.8979 292.677C81.071 294.725 79.6083 295.31 78.1456 291.946C76.6829 288.582 82.3874 285.803 82.3874 285.803C81.3636 282 83.2651 280.83 83.2651 280.83C82.3875 274.833 85.4591 271.761 85.4591 271.761C85.1666 264.886 97.5994 256.549 97.5994 256.549L104.328 255.232C104.767 256.11 105.352 256.841 105.937 257.573C109.155 261.083 111.641 260.937 114.128 257.426C115.152 258.158 116.029 258.45 117.053 258.158L120.125 260.791L124.806 264.74C123.489 266.495 122.026 268.25 120.564 270.006C119.247 271.03 118.223 271.907 118.077 272.2C118.077 272.2 117.931 272.492 117.638 273.224C116.907 275.125 115.298 279.221 115.005 282.292C114.713 286.388 113.104 287.997 112.08 290.045C111.056 292.092 111.641 296.773 109.008 297.943C106.376 299.113 101.695 301.746 101.695 301.746C103.596 297.212 104.913 293.994 105.352 293.116C106.961 289.459 105.352 286.827 100.964 287.265C96.7218 287.704 90.7248 290.63 85.8979 292.677Z" fill="white"/>
        <path d="M119.686 253.185C119.686 253.185 120.125 256.988 117.053 258.158C116.029 258.45 115.152 258.158 114.128 257.426C112.08 255.817 110.179 252.014 109.155 248.211C108.57 246.164 108.277 244.408 108.131 242.946C108.131 240.02 109.301 238.119 112.519 237.826C114.274 243.97 115.591 250.113 118.516 252.6C118.955 252.892 119.394 253.038 119.686 253.185Z" fill="white"/>
        <path d="M198.087 269.128L201.743 270.298C201.012 273.516 200.281 276.734 199.403 279.806C198.672 282.439 197.94 285.071 197.209 287.704C195.161 294.286 192.089 300.722 187.263 305.695C183.021 310.083 176.585 313.594 170.295 313.74C169.418 313.74 168.394 313.594 167.516 313.155C164.445 311.546 173.806 306.719 176 305.549H176.146H176.292C176.439 305.549 176.439 305.403 176.439 305.403C183.313 301.6 185.946 292.239 185.946 292.239C189.164 288.874 188.286 283.755 185.361 282.731C181.997 281.561 176.146 286.973 173.952 290.337C173.074 291.8 171.319 291.507 170.442 290.337C168.54 287.704 170.442 284.633 172.343 282.731C175.268 279.806 178.194 276.734 181.119 273.809C182.289 272.785 183.313 271.615 184.191 270.445C185.361 269.128 186.97 267.373 187.701 265.471L198.087 269.128Z" fill="white"/>
        <path d="M114.274 257.426C111.641 260.937 109.301 261.083 106.083 257.573C105.498 256.841 104.913 256.11 104.474 255.232C102.134 251.283 101.402 246.749 101.402 246.749C102.426 244.701 104.181 241.776 108.423 242.946C108.423 244.555 108.862 246.31 109.447 248.211C110.325 252.014 112.226 255.671 114.274 257.426Z" fill="white"/>
        <path d="M161.665 68.3003L160.788 69.6167C159.179 80.7332 153.621 85.1212 153.621 85.1212L152.012 84.8287C148.794 84.3899 146.6 81.4645 144.406 79.4167L143.967 79.1242C147.477 74.2973 149.671 68.7391 150.256 62.8883C153.913 65.3749 159.033 67.8615 161.665 68.3003Z" fill="white"/>
        <path d="M198.087 269.128L187.848 265.764L186.239 265.179L194.43 183.561L192.382 175.369L190.627 168.787L175.708 110.865L171.173 93.3123L164.006 93.1661L162.104 68.154C162.104 68.154 174.976 58.354 192.675 54.6972C260.69 40.5091 323.44 109.109 350.499 166.74H253.523C236.409 150.942 222.953 140.996 222.953 140.996L222.367 141.142C223.83 149.041 225.293 156.94 226.463 164.107C221.636 168.349 218.857 174.638 219.15 181.074C219.442 186.486 221.782 191.605 225.585 195.408C225.585 195.555 225.585 195.701 225.439 195.847L202.767 270.445L201.597 270.152L198.087 269.128ZM196.185 262.4C196.77 261.815 196.624 260.937 196.039 260.352C195.454 259.767 194.576 259.913 193.991 260.498C193.406 261.083 193.552 261.961 194.137 262.546C194.723 263.131 195.746 262.985 196.185 262.4ZM197.355 255.964C197.94 255.379 197.794 254.501 197.209 253.916C196.624 253.331 195.746 253.477 195.161 254.062C194.576 254.647 194.723 255.525 195.308 256.11C195.893 256.549 196.77 256.549 197.355 255.964Z" fill="#61D0EC"/>
        <path d="M127.438 229.196C128.608 230.659 129.194 232.707 128.608 234.462C128.462 235.193 127.877 235.925 127.292 236.51C125.683 237.826 123.489 237.095 121.88 236.071C121.734 235.925 121.734 235.925 121.587 235.779C120.71 235.193 119.832 234.316 119.247 233.438C119.101 233.146 118.955 232.853 118.808 232.561C118.808 232.561 118.808 232.414 118.662 232.414C117.931 230.659 118.223 228.319 119.832 227.149C121.295 226.125 123.05 226.564 124.659 227.295C125.683 227.587 126.707 228.319 127.438 229.196Z" fill="white"/>
        <path d="M189.603 182.098L187.409 187.656C185.946 191.313 183.898 194.823 181.558 197.895L174.829 205.647C173.952 201.405 173.367 195.701 174.683 191.605C177.17 184.145 184.483 181.513 189.603 182.098Z" fill="#eff1f6"/>
        <path d="M253.522 167.032H227.048C226.901 166.154 226.755 165.277 226.609 164.399C225.439 157.232 223.976 149.334 222.513 141.435L223.098 141.289C222.952 141.289 236.409 151.235 253.522 167.032Z" fill="white"/>
        <path d="M134.459 260.059C130.656 263.57 125.244 266.495 120.709 270.006C122.172 268.25 123.635 266.495 124.951 264.74C128.754 259.767 131.533 255.086 131.533 252.746C131.533 250.991 131.533 249.382 131.533 247.626C131.68 246.164 131.826 244.701 132.996 243.531C134.02 242.361 135.629 242.068 136.945 242.653C137.53 242.946 138.116 243.385 138.408 243.823C139.139 244.701 139.578 245.725 139.724 246.749C140.017 248.211 140.163 249.82 140.017 251.429C139.871 254.794 138.408 256.549 134.459 260.059Z" fill="white"/>
        <path d="M117.199 258.158C120.271 257.134 119.832 253.185 119.832 253.185C122.465 253.916 125.39 250.844 124.513 246.456C123.489 241.337 121.734 235.925 121.734 235.925C121.88 236.071 121.88 236.071 122.026 236.217C123.635 237.241 125.683 237.973 127.438 236.656C128.023 236.071 128.462 235.34 128.754 234.608C129.34 232.853 128.755 230.659 127.584 229.343C126.853 228.465 125.829 227.88 124.805 227.295L157.277 181.513L157.57 179.465C158.155 175.369 158.447 171.274 158.74 167.178C159.91 147.724 158.008 128.271 153.767 109.109C152.596 103.99 152.45 99.163 152.45 95.2137C158.593 102.088 164.298 108.085 173.659 111.742L175.707 111.157L190.626 169.08L192.382 175.662H192.089L189.456 182.098C184.483 181.659 177.023 184.292 174.537 191.605C173.074 195.555 173.805 201.405 174.683 205.647L152.45 230.952L138.847 243.385L138.555 243.677C138.116 243.238 137.677 242.8 137.092 242.507C135.629 241.922 134.166 242.214 133.143 243.385C132.119 244.555 131.826 246.017 131.68 247.48C131.534 249.235 131.534 250.844 131.68 252.6C131.68 254.94 128.901 259.621 125.098 264.594L120.417 260.644L117.199 258.158Z" fill="#61D0EC"/>
        <path d="M186.092 292.385C186.092 292.385 183.313 301.746 176.585 305.549C176.585 305.549 176.585 305.549 176.438 305.695C176.438 305.695 176.438 305.695 176.292 305.695C176.292 305.695 176.292 305.695 176.146 305.695C174.244 306.573 167.077 309.498 163.567 306.427C161.519 304.671 165.029 301.307 168.247 300.868C168.54 300.868 168.686 300.868 168.979 300.868C172.343 300.868 175.122 299.552 175.122 299.552C175.999 299.113 177.901 298.382 179.217 295.164C182.289 295.164 184.483 293.994 186.092 292.385Z" fill="white"/>
        <path d="M169.125 295.895C173.513 295.164 178.925 295.456 178.925 295.456C177.608 298.674 175.707 299.406 174.829 299.845C174.829 299.845 172.05 301.015 168.686 301.161C168.54 301.161 168.247 301.161 167.955 301.161C167.955 301.015 163.274 296.773 169.125 295.895Z" fill="white"/>
        <path d="M227.341 169.665V169.811C228.657 178.441 228.072 187.363 225.585 195.701C221.782 191.898 219.296 186.778 219.149 181.366C218.857 174.93 221.782 168.641 226.463 164.399C226.609 165.277 226.755 166.154 226.902 167.032C227.048 167.909 227.194 168.787 227.341 169.665Z" fill="#eff1f6"/>
        <path d="M118.808 232.415C118.808 232.415 118.808 232.561 118.954 232.561C119.101 232.853 119.247 233.146 119.393 233.438C119.978 234.316 120.856 235.194 121.733 235.779C121.733 235.779 123.489 241.191 124.512 246.31C125.39 250.698 122.465 253.77 119.832 253.038C119.393 252.892 118.954 252.746 118.662 252.307C115.883 249.821 114.42 243.677 112.665 237.534C110.909 231.683 118.808 232.415 118.808 232.415Z" fill="white"/>
        <path d="M171.173 93.4587L175.707 111.011L173.659 111.45C164.298 107.793 158.594 101.796 152.45 94.9214C152.596 88.9243 153.62 84.975 153.62 84.975C153.62 84.975 160.349 80.2944 162.104 68.1541L164.005 93.1661L171.173 93.4587Z" fill="#eff1f6"/>
        <path d="M197.209 253.916C197.794 254.501 197.794 255.379 197.355 255.964C196.77 256.549 195.892 256.549 195.307 256.11C194.722 255.525 194.722 254.648 195.161 254.062C195.746 253.477 196.624 253.331 197.209 253.916Z" fill="#272223"/>
        <path d="M196.038 260.352C196.623 260.937 196.623 261.815 196.185 262.4C195.599 262.985 194.722 262.985 194.137 262.546C193.552 261.961 193.552 261.083 193.991 260.498C194.576 259.913 195.453 259.913 196.038 260.352Z" fill="#272223"/>
        <path d="M93.9426 77.6615C94.2351 76.0526 95.6978 75.1749 97.3068 75.4675C98.9158 75.76 99.7934 77.2227 99.5008 78.8317C99.2083 80.4406 97.7456 81.3183 96.1367 81.0257C94.674 80.7332 93.6501 79.2705 93.9426 77.6615Z" fill="#272223"/>
        <path d="M96.1363 81.0257C97.7453 81.3182 99.2079 80.4406 99.5005 78.8316C99.793 77.3689 98.7691 75.7599 97.3064 75.4674C95.6975 75.1749 94.2348 76.1988 93.9423 77.6615C93.6497 79.2704 94.6736 80.7331 96.1363 81.0257ZM63.0794 82.9272C57.3749 88.7779 48.16 95.7989 39.8226 90.972C35.4345 88.4854 33.533 83.2197 33.8256 78.5391C34.1181 72.8346 37.6286 67.8614 41.5779 64.2047C45.0883 60.8405 49.1839 58.3539 53.1331 55.5748C58.3988 51.9181 63.5183 48.115 67.7601 43.2882C71.1243 39.4851 73.7571 35.2434 75.5123 30.4165C77.7064 24.712 79.1691 18.7149 82.5333 13.5955C84.7273 10.2313 89.7004 8.62232 94.3811 9.35367C96.8676 3.50289 101.841 0.43124 108.13 1.74766C112.957 2.77155 117.784 5.25814 122.026 7.74472C126.414 10.2313 130.656 13.1567 134.313 16.8134C139.725 21.9328 144.844 28.515 146.892 35.8284C147.769 39.1926 146.307 40.9479 143.966 41.2404C145.429 43.1419 146.746 45.1897 147.623 47.2374C149.525 51.333 150.402 55.5748 150.402 59.9629C150.402 60.9868 150.402 62.0106 150.256 63.0345C149.671 68.8853 147.623 74.4435 143.966 79.2704C140.602 83.8048 135.922 87.6078 130.071 89.9481C123.196 92.5809 115.736 94.1899 108.423 93.6048C106.083 93.4586 103.596 93.0197 101.256 92.2884C89.7005 89.0705 78.8765 83.6585 69.2228 76.4913L68.9302 76.345C67.175 78.3928 65.1272 80.7331 63.0794 82.9272Z" fill="white"/>
        <path d="M178.925 295.457C177.609 298.674 175.707 299.406 174.829 299.845C174.829 299.845 172.05 301.015 168.686 301.161C168.54 301.161 168.247 301.161 167.955 301.161C164.737 301.6 161.226 305.11 163.274 306.719C166.931 309.791 174.098 306.719 175.853 305.988" stroke="#272223" strokeWidth="2.5" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round"/>
        <path d="M176.439 305.695C176.439 305.695 176.439 305.695 176.292 305.842C176.292 305.842 176.292 305.842 176.146 305.842C176.146 305.842 176.146 305.842 176 305.842C173.806 307.012 164.445 311.839 167.516 313.448C168.394 313.887 169.418 314.033 170.295 314.033C176.439 313.887 182.874 310.23 187.263 305.988C192.089 301.015 195.307 294.433 197.209 287.997C198.087 285.364 198.818 282.731 199.403 280.098C200.281 276.88 201.012 273.809 201.743 270.591" stroke="#272223" strokeWidth="2.5" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round"/>
        <path d="M186.092 292.385C186.092 292.385 183.313 301.746 176.585 305.549" stroke="#272223" strokeWidth="2.5" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round"/>
        <path d="M187.994 265.764C187.262 267.665 185.653 269.274 184.483 270.737C183.459 271.907 182.436 273.077 181.412 274.101C178.486 277.027 175.561 279.952 172.635 283.024C170.734 284.925 168.686 287.997 170.734 290.63C171.612 291.8 173.367 292.092 174.244 290.63C176.292 287.265 182.289 281.853 185.653 283.024C188.579 284.047 189.31 289.167 186.239 292.531C184.776 294.14 182.436 295.31 179.071 295.456C179.071 295.456 173.659 295.164 169.271 295.895C163.421 296.919 168.101 301.015 168.101 301.015" stroke="#272223" strokeWidth="2.5" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round"/>
        <path d="M143.82 41.0941C145.283 42.9956 146.599 45.0434 147.477 47.0912C149.379 51.1867 150.256 55.4285 150.256 59.8166C150.256 60.8405 150.256 61.8644 150.11 62.8883C149.525 68.739 147.477 74.2973 143.82 79.1241C140.456 83.6585 135.775 87.4615 129.925 89.8018C123.05 92.4347 115.59 94.0436 108.277 93.4586C105.937 93.3123 103.45 92.8735 101.11 92.1421C89.5544 88.9242 78.7304 83.5122 69.0767 76.345" stroke="#272223" strokeWidth="2.5" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round"/>
        <path d="M136.215 38.1686C137.97 40.3626 141.334 41.5328 143.821 41.094C146.161 40.8014 147.624 39.0462 146.746 35.682C144.698 28.3685 139.579 21.7864 134.167 16.667C130.51 13.1565 126.415 10.2311 121.88 7.59829C117.638 5.25798 112.812 2.62512 107.985 1.60123C101.695 0.431079 96.7219 3.50275 94.2354 9.20726C89.5547 8.32964 84.5816 9.9386 82.3875 13.4491C79.0233 18.5685 77.5606 24.5655 75.3666 30.27C73.4651 35.0969 70.9785 39.3387 67.6143 43.1417C63.3725 47.9686 58.2531 51.7716 52.9874 55.4284C49.0381 58.2075 44.9426 60.8403 41.4321 64.0583C37.4828 67.8613 33.9723 72.8344 33.6798 78.3926C33.3873 83.2195 35.435 88.4852 39.6769 90.8255C48.0142 95.5062 57.2292 88.6315 62.9337 82.7807C65.1277 80.5867 67.0292 78.2464 68.9307 75.9061C71.7098 72.3956 74.1964 68.5926 76.3905 64.6433C78.1457 61.2791 79.7547 57.9149 81.2173 54.2582C82.5338 50.7477 83.9965 47.091 86.3368 44.1656C89.1159 40.6552 93.7965 38.1686 98.1846 39.485C100.379 40.2164 102.28 41.8253 104.035 43.288C106.083 45.0432 108.423 46.6522 111.056 46.5059C112.373 46.5059 113.689 45.9209 114.567 44.897C116.468 42.7029 115.298 40.0701 113.543 38.6074C112.812 38.0223 112.08 37.7298 111.349 37.291C109.594 36.5596 107.985 35.682 106.376 34.5118L105.352 33.6342C102.426 31.2939 100.964 27.4909 101.695 23.8342L101.841 23.3954C102.865 18.1297 108.131 14.6192 113.543 15.7894L117.931 16.667C121.295 17.3983 124.074 19.7387 125.244 23.1028L127.146 28.5148" stroke="#272223" strokeWidth="2.5" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round"/>
        <path d="M91.3096 69.6167C89.1155 70.6406 87.6528 72.9809 87.6528 75.3212" stroke="#272223" strokeWidth="2.5" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round"/>
        <path d="M110.617 23.2491C110.617 23.2491 110.179 27.9298 113.25 31.4402C114.274 32.6104 115.737 33.6343 117.785 34.3656" stroke="#272223" strokeWidth="2.5" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round"/>
        <path d="M113.25 31.4403C113.25 31.4403 113.689 25.297 118.223 24.2731" stroke="#272223" strokeWidth="2.5" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round"/>
        <path d="M44.6494 84.9749C48.5987 85.7062 52.548 84.0973 55.6196 81.4644C58.6913 78.8316 60.7391 75.4674 62.7868 71.9569" stroke="#272223" strokeWidth="2.5" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round"/>
        <path d="M84.7278 24.1268C83.1188 32.903 78.4381 40.9478 71.7098 46.7986C65.859 51.918 58.3993 55.2822 54.3037 61.8643" stroke="#272223" strokeWidth="2.5" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round"/>
        <path d="M107.691 8.47607C118.954 9.06115 129.486 16.0821 134.459 26.1747" stroke="#272223" strokeWidth="2.5" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round"/>
        <path d="M101.549 15.7894C99.2083 16.9596 97.3068 19.1536 96.7217 21.7865C96.1367 24.4193 96.7218 27.1984 98.3307 29.3925" stroke="#272223" strokeWidth="2.5" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round"/>
        <path d="M151.28 62.742C155.376 65.3748 159.179 67.8614 162.104 68.3002C162.104 68.3002 174.976 58.5002 192.674 54.8435C260.69 40.6553 323.439 109.256 350.499 166.886" stroke="#272223" strokeWidth="2.5" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round"/>
        <path d="M222.952 141.289C222.952 141.289 236.263 151.235 253.522 167.032" stroke="#272223" strokeWidth="2.5" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round"/>
        <path d="M152.45 230.952L174.683 205.647L181.412 197.895C183.898 194.823 185.8 191.313 187.262 187.656L189.456 182.098L192.089 175.662" stroke="#272223" strokeWidth="2.5" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round"/>
        <path d="M171.172 93.4584L175.707 111.011L190.626 168.933L192.381 175.515L194.429 183.707L186.238 265.325L187.847 265.91L198.086 269.274L201.743 270.444L202.913 270.737L225.585 196.139C225.585 195.993 225.585 195.847 225.731 195.701C228.217 187.217 228.802 178.441 227.486 169.811V169.665C227.34 168.787 227.194 167.909 227.047 167.032C226.901 166.154 226.755 165.277 226.608 164.399C225.438 157.232 223.976 149.333 222.513 141.435C219.295 124.906 215.638 108.378 212.859 99.4554" stroke="#272223" strokeWidth="2.5" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round"/>
        <path d="M153.62 85.121C153.62 85.121 160.349 80.4404 162.104 68.3L164.005 93.3121L171.172 93.4584" stroke="#272223" strokeWidth="2.5" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round"/>
        <path d="M152.45 95.0675C158.594 101.942 166.346 107.354 175.707 111.011" stroke="#272223" strokeWidth="2.5" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round"/>
        <path d="M227.34 169.664C227.34 169.519 227.34 169.519 227.34 169.664" stroke="#272223" strokeWidth="2.5" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round"/>
        <path d="M210.373 90.5331L207.448 80.5868" stroke="#272223" strokeWidth="2.5" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round"/>
        <path d="M121.587 235.925C120.71 235.34 119.832 234.462 119.247 233.584C119.101 233.292 118.955 232.999 118.808 232.707C118.808 232.707 118.808 232.561 118.662 232.561C117.931 230.805 118.223 228.465 119.832 227.295C121.295 226.271 123.05 226.71 124.659 227.441C125.683 227.88 126.707 228.611 127.438 229.489C128.608 230.952 129.194 232.999 128.608 234.755C128.462 235.486 127.877 236.217 127.292 236.802C125.683 238.119 123.489 237.387 121.88 236.364C121.88 236.071 121.734 235.925 121.587 235.925Z" stroke="#272223" strokeWidth="2.5" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round"/>
        <path d="M118.808 232.414C118.808 232.414 110.909 231.683 112.665 237.826C114.42 243.97 115.736 250.113 118.662 252.6C119.101 252.892 119.393 253.185 119.832 253.331C122.465 254.062 125.39 250.991 124.512 246.602C123.489 241.483 121.733 236.071 121.733 236.071" stroke="#272223" strokeWidth="2.5" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round"/>
        <path d="M119.686 253.185C119.686 253.185 120.125 256.988 117.053 258.158C116.029 258.45 115.152 258.158 114.128 257.426C112.08 255.817 110.179 252.014 109.155 248.211C108.57 246.164 108.277 244.408 108.131 242.946C108.131 240.02 109.301 238.119 112.519 237.826" stroke="#272223" strokeWidth="2.5" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round"/>
        <path d="M108.277 242.8C104.035 241.629 102.279 244.555 101.256 246.603C100.085 248.943 97.5989 256.403 97.5989 256.403C97.5989 256.403 85.166 264.74 85.4585 271.615C85.4585 271.615 82.3869 274.686 83.2645 280.683C83.2645 280.683 81.363 281.853 82.3869 285.656C82.3869 285.656 76.8286 288.436 78.1451 291.8C79.6078 295.164 81.0704 294.579 85.8973 292.531C90.7242 290.483 96.7212 287.558 100.963 287.119C105.351 286.68 106.96 289.459 105.351 292.97C104.912 293.848 103.596 297.212 101.694 301.6C95.8436 315.642 85.3122 341.385 85.3122 341.385" stroke="#272223" strokeWidth="2.5" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round"/>
        <path d="M101.256 246.749C101.256 246.749 101.987 251.429 104.328 255.232C104.766 256.11 105.351 256.841 105.936 257.573C109.154 261.083 111.641 260.937 114.128 257.426" stroke="#272223" strokeWidth="2.5" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round"/>
        <path d="M120.71 270.006C122.172 268.25 123.635 266.495 124.951 264.74C128.754 259.767 131.534 255.086 131.534 252.746C131.534 250.991 131.534 249.382 131.534 247.626C131.68 246.164 131.826 244.701 132.996 243.531C134.02 242.361 135.629 242.068 136.945 242.653C137.531 242.946 138.116 243.385 138.408 243.823C139.14 244.701 139.578 245.725 139.725 246.749C140.017 248.211 140.163 249.82 140.017 251.429C139.725 254.94 138.262 256.695 134.313 260.206C130.363 263.716 125.39 266.495 120.71 270.006ZM120.71 270.006C119.393 271.029 118.369 271.907 118.223 272.2C118.223 272.2 118.077 272.492 117.784 273.224C117.053 275.125 115.444 279.221 115.151 282.292C114.859 286.388 113.25 287.997 112.226 290.045C111.202 292.092 111.787 296.773 109.154 297.943C106.521 299.113 101.841 301.746 101.841 301.746" stroke="#272223" strokeWidth="2.5" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round"/>
        <path d="M79.316 293.701L62.7876 313.155" stroke="#272223" strokeWidth="2.5" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round"/>
        <path d="M144.99 80.8792C147.331 83.0732 150.11 84.5359 153.62 85.121C153.62 85.121 152.596 89.0702 152.45 95.0673C152.304 99.0165 152.596 103.843 153.766 108.963C158.008 128.124 159.91 147.578 158.74 167.032C158.447 171.127 158.155 175.223 157.57 179.318L157.277 181.366L124.805 227.148" stroke="#272223" strokeWidth="2.5" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round"/>
        <path d="M152.451 230.952L138.848 243.385" stroke="#272223" strokeWidth="2.5" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round"/>
        <path d="M226.901 167.032H253.522H350.499H388.383" stroke="#272223" strokeWidth="2.5" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round"/>
        <path d="M158.593 167.032H59.7153" stroke="#272223" strokeWidth="2.5" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round"/>
        <path d="M226.462 164.399C221.636 168.641 218.856 174.93 219.149 181.366C219.441 186.778 221.782 191.898 225.585 195.701" stroke="#272223" strokeWidth="2.5" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round"/>
        <path d="M174.683 205.647C173.805 201.405 173.22 195.701 174.536 191.605C177.169 184.145 184.483 181.513 189.456 182.098" stroke="#272223" strokeWidth="2.5" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round"/>
        <path d="M97.5996 256.549L104.328 255.232" stroke="#272223" strokeWidth="2.5" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round"/>
        <path d="M117.199 258.158L120.271 260.791L124.952 264.74" stroke="#272223" strokeWidth="2.5" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round"/>
        <path d="M405.789 167.032H420.562" stroke="#272223" strokeWidth="2.5" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round"/>
        <path d="M47.2828 167.032H18.7603" stroke="#272223" strokeWidth="2.5" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round"/>
        <path d="M56.9367 319.591L33.0947 346.797" stroke="#272223" strokeWidth="2.5" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round"/>
        <path d="M364.833 227.149H393.356" stroke="#272223" strokeWidth="2.5" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round"/>
        <path d="M393.356 199.065H336.75V227.149" stroke="#272223" strokeWidth="2.5" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round"/>
        <path d="M336.603 227.149V199.065H307.642" stroke="#272223" strokeWidth="2.5" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round"/>
        <path d="M364.833 255.232V227.149H336.749H336.603H283.068" stroke="#272223" strokeWidth="2.5" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round"/>
        <path d="M238.164 308.036H214.322" stroke="#272223" strokeWidth="2.5" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round"/>
        <path d="M199.403 279.806H259.52V308.036" stroke="#272223" strokeWidth="2.5" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round"/>
        <path d="M259.812 308.036V279.806H310.275" stroke="#272223" strokeWidth="2.5" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round"/>
        <path d="M238.165 336.119V308.036H259.52H259.812H288.92" stroke="#272223" strokeWidth="2.5" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round"/>
        <path d="M25.7816 226.417H2.08594" stroke="#272223" strokeWidth="2.5" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round"/>
        <path d="M13.6411 198.334H53.7189V226.417" stroke="#272223" strokeWidth="2.5" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round"/>
        <path d="M54.0107 226.417V198.334H110.471" stroke="#272223" strokeWidth="2.5" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round"/>
        <path d="M25.7808 254.501V226.417H53.7182H54.0108H89.5542" stroke="#272223" strokeWidth="2.5" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round"/>
        <path d="M198.087 269.128L201.744 252.307" stroke="#272223" strokeWidth="2.5" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round"/>
        <path d="M120.271 260.791L127.146 253.331" stroke="#272223" strokeWidth="2.5" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round"/>
        <path d="M300.767 116.569C313.785 125.638 324.463 137.924 331.484 152.259" stroke="#272223" strokeWidth="2.5" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round"/>
        <path d="M323.439 159.718C320.221 151.088 314.809 143.19 307.935 137.193" stroke="#272223" strokeWidth="2.5" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round"/>
        <path d="M214.176 197.749C211.982 207.841 209.934 217.934 207.74 227.88" stroke="#272223" strokeWidth="2.5" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round"/>
        <path d="M174.537 133.098C177.17 142.898 179.657 152.698 182.289 162.498" stroke="#272223" strokeWidth="2.5" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round"/>
        <path d="M164.59 206.964C159.032 216.471 151.28 224.808 142.065 230.952" stroke="#272223" strokeWidth="2.5" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round"/>
        <path d="M224.269 102.82C227.34 111.011 229.242 119.787 229.973 128.709" stroke="#272223" strokeWidth="2.5" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round"/>
        <path d="M274.585 98.0101C260.251 85.7234 241.675 78.41 222.806 77.3861" stroke="#272223" strokeWidth="2.5" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round"/>
    </svg>
)

export default Wall